<template>
    <div class="text-left SignUpSignIn" style="overflow-x:hidden">
     <div class="row  ">
      <div class="col-12 col-sm-12 col-md-12 col-lg-7  MainSection">
          <div class="h-100" >
              <!-- Logo -->
            <div class=" px-5">
                <router-link to="/login">
              <img
                  src="/assets/img/new-website/LogoQuizell.svg"
                style="height: 40px; cursor: pointer"
                alt="Quizell Product Recommendation Quiz Logo"
                 title="Product recommendation quiz"
                class="img-fluid"
                height="40"
            width="180"
                
              />
              </router-link>
            </div>
            
            <div class="  d-flex flex-column justify-content-center align-items-center " style="min-height:90%;">
            <div class=" TitleDiv">
              <p class="title">Welcome back!</p>
              <p class="SubTitle text-center">Sign back in to your account to get access to all the features and updates you’ve missed. Create a new quiz or update your current one to improve your website and boost your conversion rate.</p>
            </div>
  
            <!-- Inputs -->
            <div class="mt-5  w-75">
              
                  <div class="new-form-element mt-3 px-5">
                      <p class="m-0 pl-1">Create a password</p> 
                      <div   class="NewFormInput d-flex align-items-center w-100  py-2" >    
                         <input :type="passwordFieldType" class=" mx-2 flex-grow-1" placeholder="Enter your password" v-model="userDetail.password"/>
                          <div style="cursor:pointer;" class="mr-2" v-if="passwordFieldType == 'text'">
                                      <b-icon-eye style="color:#d1d1d1;"  @click="switchPass"/>
                                  </div>
                          <div style="cursor:pointer;" class="mr-2" v-else @click="switchPass">
                                     <b-icon-eye-slash style="color:#d1d1d1;"  />
                                     
                                  </div>
                        </div>
                  </div>
                  <div class="new-form-element mt-3 px-5">
                      <p class="m-0 pl-1">Confirm password</p> 
                      <div   class="NewFormInput d-flex align-items-center w-100  py-2" >    
                         <input type="text" class=" mx-2 flex-grow-1" placeholder="Enter your password" v-model="userDetail.confirmPassword"/>
                         
                        </div>
                        <p class="m-0 pl-1 mt-1" v-if="userDetail.confirmPassword != ''">
                          <span class="text-danger " v-if="!checkConfirmPassWord">
                           Password must match.
                            
                          </span>
                          <span class="text-success" v-else>
                            Password matched!
                          </span>
                        </p>
                  </div>
              
                   <div class="mt-4 px-5">
                                  <button :disabled="!isResetValid" @click="UpdatePassword" class="btn w-100 NewFormInput-Btn px-3 py-2"><span v-if="!resetPassSpinner">Reset Password</span>
            <div v-else class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div></button>
                              </div>
            </div>
            
        </div>
         
          </div>
      </div>
      <div  class="d-none d-lg-block  col-lg-5  objectSection lazyload" :data-bg="'~/assets/new-website/SignUpNewBg.svg'">
        
        <LazyImage
                   src="https://images.quizell.com/website/SignUpNewBg.svg"
                   :title="`Quizell login`"
                   :alt="`Quizell login`"
                   style="height:100%;width:100%;" :loading="'lazy'"
                 />
     </div>
     </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import { BIconEyeSlash } from "bootstrap-vue";
  export default {
     layout:'quiz-layout',
      components:{
          BIconEyeSlash
      },
  data(){
    return{
         userDetail:{
        email:"", 
        password:'',
        confirmPassword:''
        },
         passwordFieldType:"password",
        resetPassSpinner:false
       
    }
  },
  methods:{
   switchPass(){
              this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
          },
  
         async UpdatePassword(){
            this.resetPassSpinner= true
    try {
                  let data = {
                   
                    password:this.userDetail.password,
                    password_confirmation:this.userDetail.confirmPassword,
                    token:this.$route.params.id,
                      
                  };
                  const response = await axios.post(`/password/reset`, data);
                  if(response.status == 200 && response.data.status == 'fail'){
                    this.$toasted.show(`${response.data.data}`, {
                          theme: "toasted-primary",
                          position: "bottom-center",
                          duration: 2000
                      });
            }
            else if(response.status == 200 && response.data.status == 'success'){
              this.$toasted.show(`${response.data.data}`, {
                          theme: "toasted-primary",
                          position: "bottom-center",
                          duration: 2000
                      });
                      
  setTimeout(function () {   this.$router.push("/login") }.bind(this), 1500)
            }
              } catch (error) {
                  if (error) {
                      this.$toasted.show("Error occured ", {
                          theme: "toasted-primary",
                          position: "bottom-center",
                          duration: 2000
                      });
                  }
                  throw error;
              } finally{
                
    this.resetPassSpinner= false
              }
          }
  },
  computed:{
    
          checkConfirmPassWord(){
            return this.userDetail.password === this.userDetail.confirmPassword
          },
          isResetValid(){
            return this.userDetail.password !== ''  && this.userDetail.confirmPassword !== '' && this.checkConfirmPassWord
          }
  }
  }
  </script>
  
  <style>
   .NewFormInput {
        border: 1px solid #DEDEDE;
    border-radius: 12px;
        box-sizing: border-box;
        background:#ffffff;
        display: flex;
padding: 14px 24px;
align-items: center;
gap: 10px;
align-self: stretch;
    }
    
    .NewFormInput input {
        background: transparent;
        border: none;
        outline: none;
        color: #000;
font-family: 'Poppins';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
    .NewFormInput input:focus {
        border: none;
        outline: none;
    }
    .NewFormInput input::placeholder {
       font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    
    text-align: start;
    
    color: #D1D1D1;
    }
    
    .NewFormInput-Btn{
        background: #4D1B7E;
    border-radius: 10px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF;
    }
    .NewFormInput-Btn:hover{
        background: #FFC000;
    color: #FFFFFF;
    }
    .NewFormInput-Btn:disabled:hover {
        background: #4d1b7e !important;
      }
</style>
  <style scoped>

/* @font-face {
  font-family: 'Gilroy'; 
  src: 
       url('~@/assets/fonts/Gilroy-Light.otf')  format('opentype');
 
  font-weight: normal;
  font-style: normal;
} */
  .SignUpSignIn {
    overflow-x: hidden;
  }

  .SignUpSignIn .MainSection {
    padding: 1% 5% ;
    /* height:100%; */
    min-height:100vh;
    overflow-y: scroll ;
    background: #ffffff;
  }
 
  .MainSection .title {
    font-family: "Poppins";
    /* font-family: 'Gilroy'; */
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38.82px;
    text-align: center;
    color: #161e34;
  }
  .MainSection .titleDesc h2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    color: #161e34;
    padding-bottom:20px
  }
  .objectSection {
    background-color:#F6EDFF;
    display: flex;
  justify-content: center;
  align-items: center;
  }

  
  @media (max-width: 560px) {
    .MainSection .title {
      font-weight: 500;
      font-size: 26px;
      line-height: 60px;
    }
  
    .MainSection .TitleDiv {
      padding: 28px;
    }
  }



  </style>
  